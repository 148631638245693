<template>

<div class="page-banner" :class="{'is-inline': isInline}">

	<div class="page-banner-inner">

		<div class="page-banner-path" v-if="page.path.length">
			<router-link :to="$link(item.url)" class="page-banner-path-item" v-for="(item, index) in page.path" :key="index">{{ item.name }}</router-link>
		</div>

		<h1>{{ page.name }}</h1>

		<div class="page-banner-tabs" v-if="!window.is.mobile && ($_.contains(page.layout.banner, $constants.bannerLayout.menu.children) || $_.contains(page.layout.banner, $constants.bannerLayout.menu.siblings))">
			<router-link :to="$link(page.url)" v-for="(page, index) in layout.banner[$constants.bannerLayout.menu.children]" :key="index" class="page-banner-tabs-item">{{ page.name }}</router-link>
			<router-link :to="$link(page.url)" v-for="(page, index) in layout.banner[$constants.bannerLayout.menu.siblings]" :key="index" class="page-banner-tabs-item">{{ page.name }}</router-link>
		</div>

		<div class="page-banner-menu" v-if="window.is.mobile && ($_.contains(page.layout.banner, $constants.bannerLayout.menu.children) || $_.contains(page.layout.banner, $constants.bannerLayout.menu.siblings))">
	
			<com-menu :links="layout.banner[$constants.bannerLayout.menu.children]" />
			<com-menu :links="layout.banner[$constants.bannerLayout.menu.siblings]" />

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['page', 'isInline', 'layout'],

	components: {
		'com-menu': () => import('./layout/left/Menu')
	}

}

</script>

<style scoped>

.page-banner {
	background-position: 50% 50%;
	background-size: cover;
}

.is-aa .page-banner:not(.is-inline) {
	background-image: url(~@/assets/banner.aa.jpg);
}

.is-afa .page-banner:not(.is-inline) {
	background-image: url(~@/assets/banner.afa.jpg);
}

.is-stars .page-banner:not(.is-inline) {
	background-image: url(~@/assets/banner.stars.jpg);
}

.page-banner-inner {
	max-width: 1120px;
	margin: 0px auto;
	padding: 40px 20px;
}

.is-mobile .page-banner-inner {
	padding: 20px 10px;
}

.page-banner.is-inline .page-banner-inner {
	padding: 40px 0px;
}

.is-mobile .page-banner.is-inline .page-banner-inner {
	padding: 20px;
}

.page.is-overlap .page-banner {
	padding-bottom: 64px;
}

.page.is-tabbed .page-banner-inner {
	padding-bottom: 0px;
}

.page-banner-inner h1 {
	font-size: 48px;
	color: #fff;
	font-weight: 700;
}

.is-mobile .page-banner-inner h1 {
	font-size: 30px;
}

.page-banner-tabs {
	display: flex;
	height: 48px;
	margin-top: 40px;
}

.page-banner-tabs-item {
	background-color: #f5f5f5;
	border-radius: 13px 13px 0px 0px;
	margin-right: 4px;
	padding: 0px 20px;
	line-height: 48px;
	font-size: 18px;
	font-weight: bold;
	color: #1A234C;
}

.page-banner-tabs-item.router-link-exact-active {
	color: #C21920;
	background-color: #fff;
}

.page-banner-tabs-item:not(.router-link-exact-active):hover {
	text-decoration: underline;
	background-color: #fff;
}

.page-banner-path {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	letter-spacing: 0.7px;
	margin-bottom: 18px;
}

.is-mobile .page-banner-path {
	font-size: 10px;
	margin-bottom: 5px;
}

.page-banner-path-item:hover {
	text-decoration: underline;
}

.page-banner-path-item:after {
	content: ' /';
}

.page-banner-path-item:last-child:after {
	content: '';
}

.page-banner-menu {
	padding: 20px 0px;
}

</style>